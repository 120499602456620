import React, { Component } from "react";
import { withCookies } from "react-cookie";

class TeacherLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
    this.setUser = this.setUser.bind(this);
    this.storeUser = this.storeUser.bind(this);
    this.alertOrRedirect = this.alertOrRedirect.bind(this);
    this._userEmail = this._userEmail.bind(this);
    this._userPassword = this._userPassword.bind(this);
  }

  alertOrRedirect = (userData) => {
    if (userData.auth_error) {
      alert("Invalid email or password");
    } else {
      const userType = userData.roles.find((role) => role === "tutor") || "teacher";

      this.storeUser(userData, userType);

      const route = userType === "tutor" ? `/webcamcapture/${userData.id}` : "/studentlist";
      this.props.history.push(route);
    }
  };

  _userEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  _userPassword = (event) => {
    this.setState({ password: event.target.value });
  };

  storeUser = (userData, userType) => {
    const { cookies } = this.props;

    const domain = process.env.NODE_ENV === "development" ? "localhost" : ".tutormate.org";

    cookies.set("authToken", userData.token, {
      path: "/",
      domain,
      expires: new Date(2040, 0)
    });
    cookies.set("userRoles", userData, {
      path: "/",
      domain,
      expires: new Date(2040, 0)
    });
    cookies.set("classroomId", userData.classroom_id, {
      path: "/",
      domain,
      expires: new Date(2040, 0)
    });

    localStorage.setItem("userType", userType);
  };

  setUser = (e) => {
    e.preventDefault();

    //TODO: change url to env var and update to get user id from list of students
    const url = `${process.env.REACT_APP_API_URL || "https://www.tutormate.org/api/v2"}/auth`;

    fetch(url, {
      method: "post",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.json())
      .then((data) => {
        this.alertOrRedirect(data);
      });
  };

  render() {
    return (
      <form className="login__container" onSubmit={this.setUser}>
        <header>
          <h1>Welcome to Student Selfies</h1>
          <div className="login__icon"></div>
        </header>
        <div className="center__content">
          <input placeholder="email" type="email" onChange={this._userEmail} />
        </div>
        <div className="center__content">
          <input type="password" placeholder="password" onChange={this._userPassword} />
        </div>
        <div className="center__content">
          <input type="submit" value="Submit" />
        </div>
      </form>
    );
  }
}

export default withCookies(TeacherLogin);
