import AWS from "aws-sdk";
import interact from "interactjs";
import React, { Component, Fragment } from "react";
import AvatarEditor from "react-avatar-editor";
import { withCookies } from "react-cookie";
import { Link } from "react-router-dom";
import Webcam from "react-webcam";

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

class WebCamCapture extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageSrc: "",
      currentUser: {},
      avatarScale: 1,
      avatarRotation: 0,
      successPopup: false,
      loader: false
    };

    AWS.config.update({
      accessKeyId: "AKIAJ4AANXBJB7FOIAYA",
      secretAccessKey: "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",
      region: "us-west-2"
    });
  }

  componentDidMount() {
    if (isIOS) {
      window.addEventListener("message", this.handleSetImageSrc);
    }

    const { cookies } = this.props;

    const user = cookies.get("userRoles");

    this.setState({ currentUser: user });

    let scale = 1,
      angle = 0,
      gestureArea = document.getElementsByTagName("CANVAS")[0],
      scaleElement = document.getElementById("selfie");

    const _mobileRotationChange = this._mobileRotationChange;
    const _mobileScaleChange = this._mobileScaleChange;

    function dragMoveListener(event) {
      let target = event.target,
        // keep the dragged position in the data-x/data-y attributes
        x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx,
        y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

      // translate the element
      target.style.webkitTransform = target.style.transform = "translate(" + x + "px, " + y + "px)";

      // update the posiion attributes
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    }

    interact(gestureArea)
      .gesturable({
        onmove: function (event) {
          scale = scale * (1 + event.ds);
          scaleElement.style.webkitTransform = scaleElement.style.transform = "scale(" + scale + ")";
          _mobileScaleChange(scale);
          dragMoveListener(event);
          angle += event.da;
          gestureArea.style.webkitTransform = gestureArea.style.transform = "rotate(" + angle + "deg)";
          _mobileRotationChange(angle);
        }
      })
      .draggable({ onmove: dragMoveListener });
  }

  componentWillUnmount() {
    if (isIOS) {
      window.removeEventListener("message", this.handleSetImageSrc);
    }
  }

  handleLogout = () => {
    localStorage.removeItem("userType");

    const urlBase = process.env.REACT_APP_TUTOR_MATE_URL || "https://www.tutormate.org";

    window.location.href = urlBase + "/users/sign_out";
  };

  handleSetImageSrc = (message) => {
    this.setState({ imageSrc: message.data });
  };

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  openNativeCamera = () => {
    const message = JSON.stringify({ action: { type: "OPEN_CAMERA" } });
    window.ReactNativeWebView.postMessage(message);
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ imageSrc });
  };

  _scaleChange = (event) => {
    const newScale = event.target.value;
    this.setState({ avatarScale: newScale });
  };

  _rotationChange = (event) => {
    const newRotation = event.target.value;
    this.setState({ avatarRotation: newRotation });
  };

  _mobileScaleChange = (newScale) => {
    this.setState({ avatarScale: newScale });
  };

  _mobileRotationChange = (newRotation) => {
    this.setState({ avatarRotation: newRotation });
  };

  setEditorRef = (editor) => (this.editor = editor);

  saveImage = () => {
    const canvasScaled = this.editor.getImageScaledToCanvas();
    const dataUri = canvasScaled.toDataURL("image/jpeg", 1);
    const buf = new Buffer(dataUri.replace(/^data:image\/\w+;base64,/, ""), "base64");
    this.setState({ loader: true });
    this.uploadFile(buf, `${window.performance.now()}_${this.state.currentUser.id}_new_selfie.jpg`, "uploads/");
  };

  uploadFile = (file, fileName, path) => {
    const { cookies } = this.props;
    const photoKey = path + fileName;
    const _this = this;

    const uploadParams = {
      Bucket: "ifl-avatars",
      Key: photoKey,
      Body: file,
      ContentEncoding: "base64",
      ContentType: "image/jpeg"
    };

    const s3 = new AWS.S3();

    s3.upload(uploadParams, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        const studentId = parseInt(window.location.pathname.split("/")[2], 10);
        const l = data.Location;
        const body = JSON.stringify({
          avatar_image_url: l
        });
        const token = cookies.get("authToken");
        const url = `${process.env.REACT_APP_API_URL || "https://www.tutormate.org/api/v2"}/users/${studentId}/avatars`;

        fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          method: "post",
          body: body
        })
          .then(function (res) {
            return res.json();
          })
          .then(function () {
            _this.setState({ successPopup: true, loader: false });
          });
      }
    });
  };

  closeSuccessPopup() {
    this.setState({ successPopup: false });
    if (localStorage.getItem("userType") === "teacher") window.location = "/studentlist";
  }

  successPopup() {
    return (
      <div className="successPopup">
        <div className="successPopup__inner">
          <p>Avatar Successfully Updated!</p>
          <button onClick={this.closeSuccessPopup.bind(this)}>Okay</button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="login__container">
        {this.state.successPopup && <div>{this.successPopup()}</div>}
        {this.state.loader && (
          <div className="loader__wrapper">
            <div className="loader"></div>
          </div>
        )}
        <h1>Take that selfie!</h1>

        <div className="flex__buttons">
          {localStorage.getItem("userType") === "tutor" ? null : (
            <Link className="nav__button" to={{ pathname: "/studentlist" }}>
              Back
            </Link>
          )}
          <Link className="nav__button" to={{ pathname: "/" }} onClick={this.handleLogout}>
            Log Out
          </Link>
          {isIOS ? (
            <button onClick={this.openNativeCamera}>Open camera</button>
          ) : (
            <button onClick={this.capture}>Capture photo</button>
          )}
          <button onClick={this.saveImage}>Save Image</button>
        </div>

        {isIOS ? (
          <p className="selfie__instructions">(click the "Open camera" button above to take your selfie)</p>
        ) : (
          <Fragment>
            <Webcam audio={false} height={400} ref={this.setRef} screenshotFormat="image/jpeg" width={400} />
            <p className="selfie__instructions">
              (smile with your lips closed, and line up your lips with the blue line)
            </p>
          </Fragment>
        )}

        <form>
          <div className="form__label">Zoom</div>
          <input
            type="range"
            min="1"
            max="10"
            step="0.05"
            value={this.state.avatarScale}
            onChange={this._scaleChange}
          />
        </form>

        {/* <form>
          <div className="form__label">Rotate</div>
          <input
            type="range"
            min="-100"
            max="100"
            step="0.05"
            value={this.state.avatarRotation}
            onChange={this._rotationChange}
          />
        </form> */}

        <div id="face-line"></div>
        <div className="selfie__mask">
          <div id="selfie-editor">
            <div id="selfie">
              <AvatarEditor
                image={this.state.imageSrc}
                width={300}
                height={400}
                scale={parseFloat(this.state.avatarScale)}
                style={{ width: 300, height: 400 }}
                rotate={parseFloat(this.state.avatarRotation)}
                ref={this.setEditorRef}
              />
            </div>
          </div>
        </div>
        <p className="selfie__zoomInstructions">Drag to center your picture</p>
      </div>
    );
  }
}

export default withCookies(WebCamCapture);
