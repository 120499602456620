import React, { Component } from "react";
import { withCookies } from "react-cookie";
import { isEmpty, intersectionBy } from "lodash";
import { Link } from "react-router-dom";
import { HomeButton } from "@innovationsforlearning/tutormate-ui";

import studentImageDefault from "../images/student-demo.png";

class StudentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      students: [],
      castmates: []
    };
  }

  componentDidMount() {
    this.fetchCastmates();
  }

  fetchCastmates = () => {
    const { cookies } = this.props;

    const currentUser = cookies.get("userRoles");
    const token = cookies.get("authToken");

    const baseUrl = process.env.REACT_APP_API_URL || "https://www.tutormate.org/api/v2";

    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
      fetch(`${baseUrl}/castmates`, { headers })
        .then((res) => res.json())
        .then((res) => {
          let error = res.error || null;
          let loading = error ? false : true;

          let castmates = [...this.state.castmates, ...res].filter(
            (mate) => !isEmpty(mate.tutor_ids) && mate.role === "student"
          );
          if (currentUser.roles.indexOf("family") > -1) {
            fetch(`${baseUrl}/family_students`, { headers })
              .then((response) => response.json())
              .then((response) => {
                error = response.error || error;
                loading = error ? false : loading;

                castmates = intersectionBy(castmates, response, "id");
                this.setState({
                  castmates,
                  error,
                  loading
                });
              });
          } else {
            this.setState({
              castmates,
              error,
              loading
            });
          }
        });
    }
  };

  render() {
    return (
      <div className="student__list">
        <header>
          <h1>Whos taking the selfie?</h1>
        </header>
        <ul>
          {this.state.castmates.map((student, i) => {
            return (
              <li className="student" key={i} style={{ padding: 10, listStyleType: "none" }}>
                <div className="image__left">
                  <img
                    alt="existing selfie"
                    height="100"
                    width="100"
                    style={{ borderRadius: 50 }}
                    src={student.avatar_image_url || studentImageDefault}
                  />
                </div>
                <div className="user-name__left">
                  <Link to={{ pathname: `/webcamcapture/${student.id}` }}>
                    {`${student.first_name} ${student.last_name}`}
                  </Link>
                </div>
              </li>
            );
          })}
        </ul>
        {this.props.showHomeButton ? (
          <HomeButton
            label="MAIN HOME"
            style={{ width: "auto", height: "auto" }}
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_TUTOR_MATE_URL || "https://www.tutormate.org"}/home`;
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default withCookies(StudentList);
